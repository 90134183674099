import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Loading from "../../../components/Loading";
import Navbars from "../../../components/Navbar2";
import firebase from "../../firebase";

const AddBanner = () => {
  const [banners, setBanners] = useState([]);
  const [image, setImage] = useState();
  const [transfer, setTransfer] = useState();
  const [loading, setLoading] = useState();
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    fetchBanner();
    // checkAdmin();
  }, []);

  const checkAdmin = () => {
    if (firebase.auth().currentUser == null) {
      alert("Please login again !");
    } else if (
      firebase.auth().currentUser.uid == "skzGHRoFA1bJB61EuKznpZxYllj1"
    ) {
      setAdmin(true);
    } else {
      alert("Please login again !");
    }
  };

  async function fetchBanner() {
    var db = firebase.firestore();
    var datas = [];

    db.collection("Banners").onSnapshot((docs) => {
      docs.forEach((doc) => {
        if (doc.data() != null) {
          datas.push(doc.data());
        }
        setBanners([...datas]);
      });
    });
  }

  const removeBanner = async (uid, url) => {
    if (window.confirm("Delete the item?")) {
      try {
        setLoading(true);
        let imageRef = firebase.storage().refFromURL(url);
        let task2 = imageRef.delete();
        await task2;
        task2.then(function () {
          firebase
            .firestore()
            .collection("Banners")
            .doc(uid)
            .delete()
            .then((res) => {
              alert("Banner removed successfully !");
              window.location.reload();
            });
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const upload = async () => {
    let date = new Date();
    date = date.toString();
    let name = image.name + date;

    setLoading(true);
    if (image == null) return;
    const storageRef = firebase.storage().ref(`/Banners/${name}`);
    const task = storageRef.put(image);

    task.on("state_changed", (taskSnapshot) => {
      setTransfer(
        Math.round(taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) *
          100
      );
      console.log(transfer);
    });
    try {
      await task;
      task.then(function (snapshot) {
        storageRef.getDownloadURL().then((url) => {
          const docRef = firebase.firestore().collection("Banners").doc();
          const docId = docRef.id;

          docRef
            .set({
              uid: docId,
              url: url,
            })
            .then(function () {
              alert("image uploaded");
              window.location.reload();
            });
        });
      });
    } catch (err) {
      console.log(err);
    }
    setImage("");
  };

  return (
    <div>
      {loading == true ? (
        <Loading />
      ) : (
        <div>
          <Navbars active={"link-1"} />
          <div className="container obn">
            <center>
              <div className="tbn">
                <h2>Add New Banner</h2>
                <hr />
                <input
                  type="file"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                  className="btn-light btn"
                />

                <button className="btn btn-success " onClick={upload}>
                  Upload
                </button>
              </div>
            </center>
          </div>
          <div className="container obn">
            <center>
              <div className="tbn">
                <h2>Banners</h2>
                <hr />
                {banners.map((p) => (
                  <div>
                    <Row>
                      <Col>
                        <img src={p.url} width="100%" />
                      </Col>
                      <Col>
                        <Button
                          className="btn btn-danger mt-5"
                          onClick={() => removeBanner(p.uid, p.url)}
                        >
                          X Remove banner
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
            </center>
          </div>
        </div>
      )}
    </div>
  );
};
export default AddBanner;
