import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Loading from "../../../components/Loading";
import Navbars from "../../../components/Navbar2";
import firebase from "../../firebase";
import "./arrivals.css";

const Newarrivals = () => {
  const [rated, setRated] = useState([]);
  const [arrivals, setArrivals] = useState([]);
  const [image, setImage] = useState();
  const [transfer, setTransfer] = useState();
  const [loading, setLoading] = useState();
  const [admin, setAdmin] = useState(false);
  const [pname, setPname] = useState();

  useEffect(() => {
    fetchArrivals();
  }, []);

  const checkAdmin = () => {
    if (firebase.auth().currentUser == null) {
      alert("Please login again !");
    } else if (
      firebase.auth().currentUser.uid == "0ezqvbJKk6Sh81v0YiCTf3PWrPw1"
    ) {
      this.setState({ admin: true });
    } else {
      alert("Please login again !");
    }
  };

  const fetchArrivals = async () => {
    try {
      var datas = [];
      var snapshot = await firebase.firestore().collection("newarrivals").get();
      snapshot.forEach((doc) => {
        datas.push(doc.data());
      });
      setArrivals([...datas]);
    } catch (err) {
      console.log(err);
    }
  };

  const removeProduct = async (uid, url, cat) => {
    if (window.confirm("Delete the item?")) {
      try {
        setLoading(true);
        let imageRef = firebase.storage().refFromURL(url);
        let task2 = imageRef.delete();
        await task2;
        task2.then(function () {
          firebase
            .firestore()
            .collection(cat)
            .doc(uid)
            .delete()
            .then((res) => {
              alert("Product removed successfully !");
              window.location.reload();
            });
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const upload = async (cat) => {
    let date = new Date();
    date = date.toString();
    let name = image.name + date;
    let ppname = pname;

    setLoading(true);
    if (image == null) return;
    const storageRef = firebase.storage().ref(`/products/${name}`);
    const task = storageRef.put(image);

    task.on("state_changed", (taskSnapshot) => {
      setTransfer(
        Math.round(taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) *
          100
      );
      console.log(transfer);
    });
    try {
      await task;
      task.then(function (snapshot) {
        storageRef.getDownloadURL().then((url) => {
          const docRef = firebase.firestore().collection(cat).doc();
          const docId = docRef.id;

          docRef
            .set({
              uid: docId,
              url: url,
              name: ppname,
            })
            .then(function () {
              alert("image uploaded");
              window.location.reload();
            });
        });
      });
    } catch (err) {
      console.log(err);
    }
    setImage("");
  };

  return (
    <div>
      {loading == true ? (
        <Loading />
      ) : (
        <div>
          <Navbars active={"link-2"} />
          <hr />
          <div className="container obn">
            <center>
              <div className="tbn">
                <h2>Arrivals</h2>

                <div className="container obn">
                  <center>
                    <div className="tbn">
                      <input
                        type="file"
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                        }}
                        className="btn-light btn"
                      />
                      <input
                        type={"text"}
                        onChange={(e) => setPname(e.target.value)}
                        placeholder="enter the product name "
                        className="m-3"
                      />

                      <button
                        className="btn btn-success "
                        onClick={() => upload("newarrivals")}
                      >
                        Upload
                      </button>
                    </div>
                  </center>
                </div>
                <Row>
                  {arrivals.map((p) => (
                    <Col xs={12} md={4} lg={3}>
                      <div className="card-1">
                        <img src={p.url} width="100%" />
                        <h5 className="p-3">{p.name}</h5>
                        <Button
                          className="btn btn-danger mt-1"
                          onClick={() =>
                            removeProduct(p.uid, p.url, "newarrivals")
                          }
                        >
                          X Remove
                        </Button>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
              <hr />
            </center>
          </div>
        </div>
      )}
    </div>
  );
};
export default Newarrivals;
