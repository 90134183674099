import React, { useState } from "react";

import { Navbar, Nav, Container, NavDropdown, Row, Col } from "react-bootstrap";
import logo from "../../assets/logo.png";

const Navbars = (props) => {
  const [left1, setLeft1] = useState(0);
  const { active } = props;

  return (
    <div>
      <Navbar bg="white" variant="light" expand="lg" style={{ zIndex: 4 }}>
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} style={{ width: 150 }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto navbar-transparent" activeKey={active}>
              <Nav.Link eventKey="link-1" href="/Banners">
                BANNERS
              </Nav.Link>
              <Nav.Link eventKey="link-2" href="/Arrivals">
                ARRIVALS
              </Nav.Link>
              <Nav.Link eventKey="link-3" href="/Rated">
                RATED PRODUCTS
              </Nav.Link>

              <Nav.Link eventKey="link-4" href="/Admin_Categories">
                CATEGORIES
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navbars;
