import React, { useState, useEffect } from "react";
import { Col, Row, Carousel, CarouselItem, Spinner } from "react-bootstrap";
import "./banner.css";
import firebase from "../../containers/firebase";
const Banner = (props) => {
  const [rated, setRated] = useState([]);
  const [arrivals, setArrivals] = useState([]);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    fetchBanner();
  }, []);

  async function fetchBanner() {
    var db = firebase.firestore();
    var datas = [];

    db.collection("Banners").onSnapshot((docs) => {
      docs.forEach((doc) => {
        if (doc.data() != null) {
          datas.push(doc.data());
        }
        setBanners([...datas]);
      });
    });
  }
  return (
    <div className="container-fluid slideCon pb-4">
      {banners.length == 0 ? (
        <Spinner variant="danger" />
      ) : (
        <Row>
          <center>
            <Carousel>
              {banners.map((p) => (
                <CarouselItem>
                  <img src={p.url} className="banner" />
                </CarouselItem>
              ))}
            </Carousel>
          </center>
        </Row>
      )}
    </div>
  );
};

export default Banner;
