import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import Image1 from "../../assets/album/a1.png";
import Image2 from "../../assets/album/a2.png";
import Image3 from "../../assets/album/a3.png";
import "./slider.css";
import firebase from "../../containers/firebase";
const ImageSlider = (props) => {
  const [rated, setRated] = useState([]);
  const [arrivals, setArrivals] = useState([]);

  const images = [
    { id: 1, image: Image1, name: "Tables" },
    { id: 2, image: Image2, name: "Sofas" },
    { id: 3, image: Image3, name: "chairs" },
    { id: 3, image: Image3, name: "Tables" },
    { id: 3, image: Image3, name: "Sofas" },
    { id: 3, image: Image3, name: "chairs" },
    { id: 3, image: Image3, name: "Tables" },
    { id: 3, image: Image3, name: "chairs" },
  ];

  useEffect(() => {
    fetchArrivals();
    fetchRated();
  }, []);

  const fetchArrivals = async () => {
    try {
      var datas = [];
      var snapshot = await firebase.firestore().collection("newarrivals").get();
      snapshot.forEach((doc) => {
        datas.push(doc.data());
      });
      setArrivals([...datas]);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchRated = async () => {
    try {
      var datas2 = [];
      var snapshot = await firebase
        .firestore()
        .collection("ratedproducts")
        .get();
      snapshot.forEach((doc) => {
        datas2.push(doc.data());
      });
      setRated([...datas2]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="container-fluid slideCon mt-0">
      <h3 className="otxt text-center">{props.head}</h3>
      <hr />
      {props.head == "New Arrivals" ? (
        <div
          style={{
            display: "flex",
            overflowX: "scroll",
            overflowY: "hidden",
            margin: 0,
            paddingLeft: "1%",
            scrollbarWidth: "none",
            paddingBottom: 10,
          }}
        >
          {arrivals.map((p) => (
            <div className="card-s">
              <center>
                <img src={p.url} className="imgcom" />
                <p>
                  <b>{p.name}</b>
                </p>
              </center>
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            overflowX: "scroll",
            overflowY: "hidden",
            margin: 0,
            paddingLeft: "1%",
            scrollbarWidth: "none",
            paddingBottom: 10,
          }}
        >
          {rated.map((p) => (
            <div className="card-s">
              <center>
                <img src={p.url} className="imgcom" />
                <p>
                  <b>{p.name}</b>
                </p>
              </center>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageSlider;
