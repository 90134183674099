import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ReactComponent as SocialMedias } from "../../assets/socials.svg";
import { ReactComponent as CopyRight } from "../../assets/copyright2.svg";
import "./footer.css";

function Footer() {
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col md={8} lg={8} xs={12}></Col>
          <Col>
            <center>
              <button className="otbns2">OTHER SERVICES</button>
            </center>
          </Col>
        </Row>
        <br />

        <center>
          <Row>
            <Col md={4} lg={4} xs={12}>
              <button className="otbns">Company</button>
            </Col>
            <Col md={4} lg={4} xs={12}>
              <button className="otbns">What we do!</button>
            </Col>
            <Col md={4} lg={4} xs={12}>
              <button className="otbns">Gallery</button>
            </Col>
          </Row>
          <Row>
            <Col md={3} lg={3} xs={12}>
              <br />
              <SocialMedias />
              <br /> <br />
              <CopyRight />
            </Col>
          </Row>

          <Row>
            <Col></Col>
            <Col md={2} lg={2} xs={12}>
              <center>
                <button className="fbn">Terms of use</button>
              </center>
            </Col>
            <Col md={2} lg={2} xs={12}>
              <center>
                <button className="fbn">Privacy Policy</button>
              </center>
            </Col>
            <Col md={2} lg={2} xs={12}>
              <center>
                <button className="fbn">License</button>
              </center>
            </Col>
            <Col md={2} lg={2} xs={12}>
              <center>
                <button className="fbn text-center">Imprint</button>
              </center>
            </Col>
            <Col></Col>
          </Row>
        </center>
      </Container>
    </div>
  );
}

export default Footer;
