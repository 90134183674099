import React from "react";
import About from "../../components/About";
import NavBar from "../../components/NavBar";
import Section from "../Section1";
import "./home.css";
import { ReactComponent as Services } from "../../assets/services.svg";
import ImageSlider from "../../components/ImageSlider";
import { ReactComponent as Sec } from "../../assets/Group1.svg";
import Group1 from "../../assets/Group1.png";
import Group2 from "../../assets/Group2.png";
import Group3 from "../../assets/Group3.png";
import Group4 from "../../assets/Group4.png";
import Group5 from "../../assets/Group5.png";
import Footer from "../../components/Footer";
import Section0 from "../Section0";
import firebase from "../firebase";
import Banner from "../../components/Banner";
// import { ReactComponent as Ocard1 } from "../../assets/Cards/gp1.svg";
// import { ReactComponent as Ocard2 } from "../../assets/Cards/gp2.svg";
// import { ReactComponent as Ocard3 } from "../../assets/Cards/gp3.svg";
// import { ReactComponent as Ocard4 } from "../../assets/Cards/gp4.svg";
// import { ReactComponent as Ocard5 } from "../../assets/Cards/gp5.svg";
import { Fade, Zoom } from "react-reveal";
const Home = () => {
  return (
    <div className="home">
      <NavBar />

      <div className="bg-white">
        <ImageSlider head={"New Arrivals"} />

        <Fade left>
          <Banner />
        </Fade>
      </div>
      <h2 className="serv">Our Services</h2>
      <Fade left>
        <Section image={Group1} cat={"interior_products"} position={"right"} />
      </Fade>
      <br />
      <br />
      <Fade right>
        <Section image={Group2} cat={"bedroom_products"} position={"left"} />
      </Fade>
      <br />
      <br />
      <Fade left>
        <Section image={Group3} cat={"dining_products"} position={"right"} />
      </Fade>
      <br />
      <br />
      <Fade right>
        <Section image={Group4} cat={"courtyard_products"} position={"left"} />
      </Fade>
      <br />
      <br />
      <Fade left>
        <Section image={Group5} cat={"external_products"} position={"right"} />
      </Fade>
      <br />
      <br />

      <ImageSlider head={"Rated products"} />

      <Footer />
    </div>
  );
};

export default Home;