import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import React from "react";
import Home from "./containers/Home";
import About from "./containers/About";
import Contact from "./containers/Contact";
import Login from "./containers/Admin/Login";
import AddBanner from "./containers/Admin/Banners";
import Gallery from "./containers/Gallery";
import Categories from "./containers/Admin/Categories";
import RatedProducts from "./containers/Admin/Rated";
import Newarrivals from "./containers/Admin/Arrivals";

const Entrypoint = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contactus" element={<Contact />} />
        <Route exact path="/Admin_login" element={<Login />} />
        <Route exact path="/Banners" element={<AddBanner />} />
        <Route exact path="/Arrivals" element={<Newarrivals />} />
        <Route exact path="/Rated" element={<RatedProducts />} />
        <Route exact path="/Gallery/:cat" element={<Gallery />} />
        <Route exact path="/Admin_Categories" element={<Categories />} />
      </Routes>
    </Router>
  );
};

export default Entrypoint;
