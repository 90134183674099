import React from "react";
import { Container, Navbar, NavDropdown, Nav, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import "./nav.css";
import FbIcon from "../../assets/icons/fb.png";
import YTIcon from "../../assets/icons/yt.png";
import IGIcon from "../../assets/icons/ig.png";
import TWIcon from "../../assets/icons/tw.png";
import WHBIcon from "../../assets/icons/whp.png";


function NavBar() {
  return (
    <div className="main1">
      <Container className="main2">
      
        <center>
          <img src={Logo} className="logo w-100" />
          <Row className="icnRow" style={{ marginRight: 200, marginLeft: 200 }}>
            <Col>
              <a>
                <img src={FbIcon} className="icn" />
              </a>
            </Col>
            <Col>
              <a>
                <img src={IGIcon} className="icn" />
              </a>
            </Col>
            <Col>
              <a>
                <img src={TWIcon} className="icn" />
              </a>
            </Col>
            <Col>
              <a>
                <img src={YTIcon} className="icn" />
              </a>
            </Col>
          </Row>
        </center>
      </Container>
      

        <button className="whatsapp-button" onClick={() => window.open("https://wa.me/919846083216")}>
            <img src={WHBIcon} alt="WhatsApp" />
        </button>
    

      <Row className="mt-3">
        <Col>
          <center>
            <Link to={"/"}>
              <button className="hmbn">HOME</button>
            </Link>
          </center>
        </Col>
        <Col>
          <center>
            <Link to={"/about"}>
              <button className="hmbn">ABOUT US</button>
            </Link>
          </center>
        </Col>
        <Col>
          <center>
            <Link to={"/contactus"}>
              <button className="hmbn">CONTACT US</button>
            </Link>
          </center>
        </Col>
      </Row>
    </div>
  );
}

export default NavBar;
