import React from "react";
import "./about.css";
import { ReactComponent as Home } from "../../assets/home.svg";
import { ReactComponent as Facebook } from "../../assets/facebook2.svg";
import { ReactComponent as Instagram } from "../../assets/instagram2.svg";
import { ReactComponent as Twitter } from "../../assets/twitter2.svg";
import { ReactComponent as Copyright } from "../../assets/copyright.svg";
import Logo from "../../assets/logo.png";
import EllipseLayer from "../../assets/ellipse.png";

import BgLayer from "../../assets/bglayer.png";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="abtcon">
      <div style={{ position: "relative", zIndex: 2 }}>
        <Link to={"/"}>
          <button className="hmbtn">
            <Home />
          </button>
        </Link>

        <Row>
          <Col></Col>
          <Col xs={10} md={4} lg={4}>
            <img src={Logo} className="w-100 pt-5" />
          </Col>
          <Col></Col>
        </Row>
        <div className="abt-main ">
          <h1>ABOUT US</h1>
          <Container fluid>
            <Row>
              <Col xs={12} md={6} lg={4}>
                <h4 className="text-center hds m-0 p-3">About Us</h4>
                <p className="abt-con">
                  "The Pento Doors", though registered recetly are in the local
                  market for more than a century catering the needs of many a
                  customers. It's the vast exposure that we gained led us to
                  venture out to broader spectrum of specialised quality
                  construction materials and workmanship supply to the
                  discerning customers.
                </p>
              </Col>

              <Col xs={12} md={6} lg={4}>
                <h4 className="text-center hds m-0 p-3">Vision</h4>
                <p className="ms-con">
                  To avail the most innovative and modern concepts in the
                  construction (especially in the home-making) area to the
                  discerning customers according to their taste and budget.
                </p>
              </Col>

              <Col xs={12} md={6} lg={4}>
                <h4 className="text-center hds m-0 p-3">Mission</h4>
                <p className="ms-con">
                  Pursue the passion and work relentlessly to establish our slot
                  as a leader in the field of construction materials supplier
                  and maintan a long lasting bond and trust in making their
                  dream come true in a sizeable budget. Cast our vast pool of
                  talented engineers and skilled workers time-bound with quality
                  materials.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="container p-5">
          <Row>
            <Col>
              <button className="tnbn">
                <Facebook />
              </button>
            </Col>

            <Col>
              <button className="tnbn">
                <Instagram />
              </button>
            </Col>

            <Col>
              <button className="tnbn">
                <Twitter />
              </button>
            </Col>
            <Col xs={12} lg={10} md={10}></Col>
          </Row>
          <br />
          <h6>
            <span style={{ fontFamily: "fantasy" }}>© </span>
            <b>2022 Pento</b>
          </h6>
          <br />
        </div>
      </div>
    </div>
  );
};

export default About;
