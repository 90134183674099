import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyBs0l1M7sbUNHQQOiYibOwRT0zYOjKtyww",
  authDomain: "pentodoors-55c9c.firebaseapp.com",
  projectId: "pentodoors-55c9c",
  storageBucket: "pentodoors-55c9c.appspot.com",
  messagingSenderId: "682981945443",
  appId: "1:682981945443:web:c91d6029f97b63263ac856",
  measurementId: "G-2D0TRJH8V2",
};
firebase.initializeApp(config);

export default firebase;
// export const database = firebase.database();
// export const auth = firebase.auth();
// export const storage = firebase.storage();
// export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
// export const messaging = firebase.messaging();
