import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Loading from "../../../components/Loading";
import Navbars from "../../../components/Navbar2";
import firebase from "../../firebase";
import "./products.css";
import Select from "react-select";

const Categories = () => {
  const [products, setProducts] = useState([]);

  const [image, setImage] = useState();
  const [transfer, setTransfer] = useState();
  const [loading, setLoading] = useState();
  const [admin, setAdmin] = useState(false);
  const [pname, setPname] = useState();
  const [price, setPPrice] = useState();
  const [pdesc, setPDesc] = useState();
  const [cat, setcat] = useState();
  const options = [
    { value: "interior_products", label: "Interior And Fine Arts" },
    { value: "dining_products", label: "Dining And Living Room Design" },
    { value: "bedroom_products", label: "Bed Room Design" },
    { value: "courtyard_products", label: "Court Yard Design" },
    { value: "external_products", label: "External And Garder Design" },
  ];
  useEffect(() => {}, []);

  const checkAdmin = () => {
    if (firebase.auth().currentUser == null) {
      alert("Please login again !");
    } else if (
      firebase.auth().currentUser.uid == "0ezqvbJKk6Sh81v0YiCTf3PWrPw1"
    ) {
      this.setState({ admin: true });
    } else {
      alert("Please login again !");
    }
  };

  const fetchProducts = async () => {
    try {
      var datas = [];
      var snapshot = await firebase.firestore().collection(cat.value).get();
      snapshot.forEach((doc) => {
        datas.push(doc.data());
      });
      setProducts([...datas]);
    } catch (err) {
      console.log(err);
    }
  };

  const removeProduct = async (uid, url, cat) => {
    if (window.confirm("Delete the item?")) {
      try {
        setLoading(true);
        let imageRef = firebase.storage().refFromURL(url);
        let task2 = imageRef.delete();
        await task2;
        task2.then(function () {
          firebase
            .firestore()
            .collection(cat)
            .doc(uid)
            .delete()
            .then((res) => {
              alert("Product removed successfully !");
              window.location.reload();
            });
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const upload = async (cat) => {
    let date = new Date();
    date = date.toString();
    let name = image.name + date;
    let ppname = pname;

    setLoading(true);
    if (image == null) return;
    const storageRef = firebase.storage().ref(`/products/${name}`);
    const task = storageRef.put(image);

    task.on("state_changed", (taskSnapshot) => {
      setTransfer(
        Math.round(taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) *
          100
      );
      console.log(transfer);
    });
    try {
      await task;
      task.then(function (snapshot) {
        storageRef.getDownloadURL().then((url) => {
          const docRef = firebase.firestore().collection(cat).doc();
          const docId = docRef.id;

          docRef
            .set({
              uid: docId,
              url: url,
              name: ppname,
              desc: pdesc,
              price: price,
            })
            .then(function () {
              alert("image uploaded");
              window.location.reload();
            });
        });
      });
    } catch (err) {
      console.log(err);
    }
    setImage("");
  };

  return (
    <div>
      {loading == true ? (
        <Loading />
      ) : (
        <div>
          <Navbars active={"link-4"} />
          <hr />
          <div className="container">
            <Row>
              <Col>
                <Select
                  name="form-field-name"
                  options={options}
                  placeholder="Select a category"
                  searchable={false}
                  onChange={setcat}
                />
              </Col>
              <Col>
                <button
                  className="btn btn-success"
                  onClick={() => fetchProducts()}
                >
                  Fetch
                </button>
              </Col>
            </Row>
          </div>
          <hr />
          <div className="container obn">
            <div className="tbn">
              <div
                className="container obn"
                style={{ backgroundColor: "#eee", padding: 10 }}
              >
                <center>
                  {cat != null ? <h2>{cat.label}</h2> : null}

                  <div className="tbn">
                    <Row>
                      <Col>
                        <input
                          type="file"
                          onChange={(e) => {
                            setImage(e.target.files[0]);
                          }}
                          className="btn-light btn"
                        />
                      </Col>
                      <Col>
                        <input
                          type={"text"}
                          onChange={(e) => setPname(e.target.value)}
                          placeholder="enter the product name "
                          className="m-3"
                        />
                      </Col>
                      <Col>
                        <input
                          type={"text"}
                          onChange={(e) => setPDesc(e.target.value)}
                          placeholder="enter the product description "
                          className="m-3"
                        />
                      </Col>
                      <Col>
                        <input
                          type={"text"}
                          onChange={(e) => setPPrice(e.target.value)}
                          placeholder="enter the product price "
                          className="m-3"
                        />
                      </Col>
                      <Col>
                        <button
                          className="btn btn-success "
                          onClick={() => upload("interior_products")}
                        >
                          Upload
                        </button>
                      </Col>
                    </Row>
                  </div>
                </center>
              </div>
              <br />
              <Row>
                {products.map((p) => (
                  <Col xs={12} md={4} lg={3}>
                    <div className="card-1">
                      <img src={p.url} width="100%" />
                      <h5 className="p-3">{p.name}</h5>
                      <p className="">₹{p.price}</p>
                      <Button
                        className="btn btn-danger mt-1"
                        onClick={() => removeProduct(p.uid, p.url, cat.value)}
                      >
                        X Remove
                      </Button>
                      <p className="">{p.desc}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>

            <hr />
          </div>
        </div>
      )}
    </div>
  );
};
export default Categories;
