import React, { useRef } from "react";
import "./contact.css";
import { ReactComponent as Home } from "../../assets/home.svg";
import { ReactComponent as Facebook } from "../../assets/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/instagram.svg";
import { ReactComponent as Twitter } from "../../assets/twitter.svg";
import { ReactComponent as Copyright } from "../../assets/copyright.svg";
import Logo from "../../assets/logo.png";
import Phone from "../../assets/icons/phone.png";
import Mail from "../../assets/icons/mail.png";
import BgLayer from "../../assets/bglayer.png";
import { Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

const Contact = () => {
  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const name = data.get("name");
    const email = data.get("email");
    const message = data.get("message");

    console.log(name);

    if (name != "" && email != "" && message != "") {
      emailjs
        .sendForm(
          "service_j1j6d6e",
          "template_gfzf4au",
          form.current,
          "scvrSDc6cDdkL5lpW"
        )
        .then(
          (result) => {
            alert("Message sent !");
            document.getElementById("form").reset();
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      alert("Please fill out all details !");
    }
  };

  return (
    <div className="contact">
      <img src={BgLayer} className="w-100 layer" style={{ zIndex: -1 }} />
      <div className="contact-main p-5" style={{ zIndex: 2 }}>
        <Link to={"/"}>
          <button
            style={{ background: "none", border: "none" }}
            className="btn"
          >
            <Home />
          </button>
        </Link>

        <div className="cnt-2 container mt-5">
          <Row>
            <Col md={4} lg={4} xs={12}></Col>
            <Col md={4} lg={4} xs={12}>
              <img src={Logo} className="w-100" />
            </Col>
            <Col md={4} lg={4} xs={12}></Col>
          </Row>
          <h3 className="text-center">Contact us</h3>
          <hr />
          <Form ref={form} onSubmit={sendEmail} id="form">
            <input
              type={"text"}
              className="form-control"
              placeholder="type your name.."
              name="name"
            />
            <br />
            <input
              type={"email"}
              className="form-control"
              placeholder="type your email.."
              name="email"
            />
            <br />
            <textarea
              className="form-control"
              placeholder="type your message.."
              name="message"
            />
            <br />
            <button
              type="submit"
              style={{
                background: "none",
                borderColor: "#00A651",
                paddingRight: 15,
                paddingLeft: 15,
                color: "#00A651",
                borderRadius: 15,
                marginBottom: 30,
              }}
            >
              submit
            </button>
          </Form>
          <hr />
          <h5>Location</h5> <hr />
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d250652.77048594537!2d75.83771627463845!3d11.00485941001876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7b140a2716e43%3A0x1052c630139433cb!2sPento%20Doors%20and%20Frames!5e0!3m2!1sen!2sin!4v1660647859823!5m2!1sen!2sin"
            width={"100%"}
            height={450}
            loading={"lazy"}
            referrerpolicy={"no-referrer-when-downgrade"}
          ></iframe>
        </div>
      </div>

      <div className="footer" style={{ zIndex: 3 }}>
        <div className="container">
          <Row>
            <Col md={3} lg={3} xs={12}>
              <Row className="mt-1">
                <Col>
                  <button className="tnbn">
                    <Facebook />
                  </button>
                </Col>

                <Col>
                  <button className="tnbn">
                    <Instagram />
                  </button>
                </Col>

                <Col>
                  <button className="tnbn">
                    <Twitter />
                  </button>
                </Col>
                <Col xs={12} lg={10} md={10}></Col>
              </Row>
              <h6 className="mt-2">
                <span style={{ fontFamily: "fantasy" }}>© </span>
                2022 Pento
              </h6>
            </Col>

            <Col></Col>
            <Col md={3} lg={3} xs={12}>
              <Row className="mt-1">
                <Col lg={2} md={2} xs={1}>
                  <img src={Phone} />
                </Col>
                <Col>+91 98460 83216</Col>
              </Row>
              <Row className="mt-2">
                <Col lg={2} md={2} xs={1}>
                  <img src={Mail} />
                </Col>
                <Col>pentodoors@gmail.com</Col>
              </Row>
            </Col>
          </Row>
          <br />

          <br />
          <Row>
            <Col></Col>
            <Col md={2} lg={2} xs={12}>
              <center>
                <button className="fbn">Terms of use</button>
              </center>
            </Col>
            <Col md={2} lg={2} xs={12}>
              <center>
                <button className="fbn">Privacy Policy</button>
              </center>
            </Col>
            <Col md={2} lg={2} xs={12}>
              <center>
                <button className="fbn">License</button>
              </center>
            </Col>
            <Col md={2} lg={2} xs={12}>
              <center>
                <button className="fbn text-center">Imprint</button>
              </center>
            </Col>
            <Col></Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Contact;
