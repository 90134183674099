import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./sec1.css";

const Section1 = (props) => {
  const navigate = useNavigate();

  return (
    <div className="sect">
      <Row>
        <Col></Col>
        <Col xs={12} md={10} lg={10}>
          <img src={props.image} className="w-100" />

          {/* {props.position == "left" ? (
            <button
              style={{ position: "relative", left: "36%", top: "-2%" }}
              className="vbtn"
              onClick={() => navigate("/Gallery/" + props.cat)}
            >
              View more
            </button>
          ) : (
            <button
              style={{ position: "relative", left: "50%", top: "-2%" }}
              className="vbtn"
              onClick={() => navigate("/Gallery/" + props.cat)}
            >
              View more
            </button>
          )} */}
          <center>
            <button
              className="vbtn"
              onClick={() => navigate("/Gallery/" + props.cat)}
            >
              View more
            </button>
          </center>
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
};

export default Section1;
