import React, { useEffect, useState } from "react";
import "./gallery.css";
import { ReactComponent as Home } from "../../assets/home.svg";
import { ReactComponent as Facebook } from "../../assets/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/instagram.svg";
import { ReactComponent as Twitter } from "../../assets/twitter.svg";
import { ReactComponent as Copyright } from "../../assets/copyright.svg";
import Logo from "../../assets/logo.png";

import firebase from "../../containers/firebase";

import BgLayer from "../../assets/bglayer.png";
import { Col, Row, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

const Gallery = () => {
  const [products, setProducts] = useState([]);
  const { cat } = useParams();

  useEffect(() => {
    fetchProducts();
  }, []);

  async function fetchProducts() {
    if (cat != null) {
      try {
        var datas = [];
        var snapshot = await firebase.firestore().collection(cat).get();
        snapshot.forEach((doc) => {
          datas.push(doc.data());
        });
        setProducts([...datas]);
      } catch (err) {
        console.log(err);
      }
    }
  }

  return (
    <div className="contact">
      <img src={BgLayer} className="w-100 layer" style={{ zIndex: -1 }} />
      <div className="contact-main p-5" style={{ zIndex: 2 }}>
        <Link to={"/"}>
          <button
            style={{ background: "none", border: "none" }}
            className="btn"
          >
            <Home />
          </button>
        </Link>
        {products.length == 0 ? (
          <Spinner animation="border" variant="danger" />
        ) : (
          <div className="cnt-2 container mt-5">
            <Row>
              {products.map((p) => (
                <Col xs={12} md={4} lg={3}>
                  <div className="card-1" >
                    <img src={p.url} />
                    <div className="p-3">
                      <h5>{p.name}</h5>
                      <p className="text-success">₹{p.price}</p>
                      <p className="text-secondary">
                        <i>{p.desc}</i>
                      </p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>

      <div className="footer" style={{ zIndex: 3 }}>
        <div className="container">
          <Row>
            <Col>
              <button className="tnbn">
                <Facebook />
              </button>
            </Col>

            <Col>
              <button className="tnbn">
                <Instagram />
              </button>
            </Col>

            <Col>
              <button className="tnbn">
                <Twitter />
              </button>
            </Col>
            <Col xs={12} lg={10} md={10}></Col>
          </Row>
          <br />
          <h6>
            <span style={{ fontFamily: "fantasy" }}>© </span>
            2022 Pento
          </h6>
          <br />
          <Row>
            <Col></Col>
            <Col md={2} lg={2} xs={12}>
              <center>
                <button className="fbn">Terms of use</button>
              </center>
            </Col>
            <Col md={2} lg={2} xs={12}>
              <center>
                <button className="fbn">Privacy Policy</button>
              </center>
            </Col>
            <Col md={2} lg={2} xs={12}>
              <center>
                <button className="fbn">License</button>
              </center>
            </Col>
            <Col md={2} lg={2} xs={12}>
              <center>
                <button className="fbn text-center">Imprint</button>
              </center>
            </Col>
            <Col></Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
